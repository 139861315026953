
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import Cookie from "~/utils/cookie/cookie.js";
import { goodsInfoHttp, dealGoodsData } from "~/api/goods";
import { addHistTory } from "../../api/order";
import { queryPreSaleSerList } from "~/api/cusSer";
@Component({
  name: "goodsDetail",
  layout: function (context) {
    return "index5Layout";
  },
  components: {
    djhHeaderNav9: require("~/components/comm/djhHeaderNav/index9.vue").default,
    logoSearch: require("~/components/comm/logoSearch/index.vue").default,
    goodsTitleBar: require("~/components/comm/goods/goodsTitleBar.vue").default,
    detailGoodsInfo3: require("~/components/comm/goods/detailGoodsInfo3.vue")
      .default,
    detailHotBrowse: require("~/components/comm/goods/detailHotBrowse.vue")
      .default,
    detailGoodsDetail2:
      require("~/components/comm/goods/detailGoodsDetail2.vue").default,
    detailAccountScreenshot:
      require("~/components/comm/goods/detailAccountScreenshot.vue").default,
    floatMenu8: require("~/components/comm/floatMenu/index8.vue").default,
    breadCrumb4: require("~/components/comm/breadCrumb/index4.vue").default,
  },
  async asyncData({ query, params, req }: any) {
    console.log(
      Cookie.getCookiesInServer(req, "userId"),
      "=====req====322======"
    );
    let userId: any = Cookie.getCookiesInServer(req, "userId");
    let queryParams: any = {
      goodsSn: params.sn,
    };
    if (userId) {
      queryParams = {
        goodsSn: params.sn,
        userId: userId,
      };
    }
    console.log(queryParams, "====queryParams======");
    try {
      let { data } = await goodsInfoHttp({ params: queryParams, req });
      console.log(data, "xiaolong66");
      if (data.code === 0) {
        return { goodsInfo: dealGoodsData(data.data) };
      } else {
        return { goodsInfo: {} };
      }
    } catch (e) {
      return { goodsInfo: {} };
    }
  },
})
export default class goodsDetail extends Vue {
  @State((state) => state.web.distributorsObject) distributorsObject!: any;
  public offsetHeight: any;
  public isMyGoods: any;
  public goodsInfo: any = {}; // 接收asyncData中的商品数据
  private flag: number = 0; // 滚动判断标识
  private goodsTitleBarList: Array<any> = [
    { title: "首页", url: "/" },
    { title: "全部游戏", url: `/allgame` },
    { title: "", url: "#" },
    { title: "", url: "#", color: "#999" },
  ];
  private preSaleSerObj: any = {}; // 接受asyncData中的客服数据
  private snAndIsMyGoods: any = "";
  private goodsTitleHeight: any = 0;
  private goodsTitleIsShow: any = false;
  private selfToen: any = "666";
  private historyLog: any = [];
  private goodsList: any = [];

  mounted() {
    console.log(this.goodsInfo.serverName);
    this.goodsTitleBarList[2].title = this.goodsInfo.gameName;
    console.log(this.goodsTitleBarList[2].title);
    this.goodsTitleBarList[2].url = `/wares?gid=${this.goodsInfo.gameId}&gt=${this.goodsInfo.goodsTypeId}`;
    console.log(this.goodsTitleBarList[2].url);
    this.goodsTitleBarList[3].title = this.goodsInfo.goodsTypeName;
    console.log(this.goodsTitleBarList[3].title);

    // window.addEventListener("scroll",this.scrollListenView) //添加滚动事件
    this.snAndIsMyGoods = `${this.goodsInfo.goodsSn}-${
      this.isMyGoods(this.goodsInfo.pullId) ? "卖家" : "买家"
    }`;
    localStorage.setItem("historyLog", this.historyLog);
    let token = localStorage.getItem("token");
    if (token) {
      this.gethistrotylist();
    } else {
      interface Goods {
        name: string;
        price: number;
      }

      let savedGoodsList: Goods[] = JSON.parse(
        localStorage.getItem("goodsList") || "[]"
      );
      this.goodsList.push(...savedGoodsList);
      this.goodsList.push({
        goods_sn: this.goodsInfo.goodsSn,
        big_title: this.goodsInfo.bigTitle,
        game_id: this.goodsInfo.gameId,
        thumbnail: this.goodsInfo.thumbnail,
        game_name: this.goodsInfo.gameName,
        area_name: this.goodsInfo.areaName,
        server_name: this.goodsInfo.serverName,
        goods_code: this.goodsInfo.goodsCode,
        price: this.goodsInfo.price,
        goods_id: this.goodsInfo.id,
      });
      // 将数组转换为字符串并存储到 localStorage 中
      localStorage.setItem("goodsList", JSON.stringify(this.goodsList));
      console.log(savedGoodsList);
    }
  }

  // destroyed() {
  //   //清除滚动事件
  //   window.removeEventListener('scroll', this.scrollListenView)
  // }
  @Emit()
  // 滚动监听
  // scrollListenView() {
  //   let a: number = document.documentElement ? document.documentElement.scrollTop : document.body.scrollTop // 滚动的距离
  //   let b: number = document.documentElement.scrollHeight // 网页高度
  //   if (document.getElementById('alpha')){
  //     this.offsetHeight = document.getElementById('alpha') // 屏幕高度
  //   } else {
  //     this.offsetHeight = {offsetHeight: 785}
  //   }
  //   let d = b - this.offsetHeight['offsetHeight'] - 368 // 用于计算滚动阈值
  //   if (a > 770 && a < d) {
  //     this.flag = 1
  //   } else if (a >= d) {
  //     this.flag = 2
  //   } else {
  //     this.flag = 0
  //   }
  // }
  // 获取售前客服数据
  async getPreSaleSerList() {
    try {
      let { data } = await queryPreSaleSerList({
        params: {
          gameId: this.goodsInfo.gameId,
          goodsTypeId: this.goodsInfo.goodsTypeId,
          pages: 1,
          pageSize: 1,
        },
      });
      console.log(data, "kljkljkl");
      if (data.code === 0 && data.data.data.length > 0) {
        this.preSaleSerObj = data.data.data[0];
        console.log(data, "-------------------------------------------156");
      } else {
        this.preSaleSerObj = {};
      }
    } catch (error) {
      this.preSaleSerObj = {};
      console.log(error);
    }
  }

  async gethistrotylist() {
    let res = await addHistTory({
      method: "POST",
      data: {
        goods_sn: this.goodsInfo.goodsSn,
        big_title: this.goodsInfo.bigTitle,
        game_id: this.goodsInfo.gameId,
        thumbnail: this.goodsInfo.thumbnail,
        game_name: this.goodsInfo.gameName,
        area_name: this.goodsInfo.areaName,
        server_name: this.goodsInfo.serverName,
        goods_code: this.goodsInfo.goodsCode,
        price: this.goodsInfo.price,
        goods_id: this.goodsInfo.id,
      },
    });
  }
  getheight() {
    let obj: any = this.$refs.goodsTitle;
    let h = window.getComputedStyle(obj).height; //通过这个方法，获取宽高
    this.goodsTitleHeight = parseInt(h.substring(0, h.length - 2));
  }
  modifyGoodsTitleIsShow() {
    this.goodsTitleIsShow = !this.goodsTitleIsShow;
    // this.$forceUpdate();
    console.log("dgsahdjkas", this.goodsTitleIsShow);
  }
  goGoodsInfoPlace() {
    window.scrollTo({
      top: 750,
      behavior: "smooth",
    });
  }
}
